
AppComponents.push({

    selector: '.btn-danger',

    init: function() {
        $(this.selector).on('click', function (e) {
            return confirm('Är du säker att du vill radera?')
        })
    },

})
