
AppComponents.push({



    /**
     * Core
     */

    generatePalette: function(set, size, opacity) {
        if (opacity === undefined) {
            opacity = 1
        }

        return palette(set, size).map(function (hex) {
            r = parseInt(hex.substring(0,2), 16);
            g = parseInt(hex.substring(2,4), 16);
            b = parseInt(hex.substring(4,6), 16);
            return 'rgba('+r+','+g+','+b+','+opacity+')';
        })
    },

    init: function() {
        this.initPieChart()
        this.initLineChart()
        this.initBarChart()
    },



    /**
     * Pie
     */

    pieLegendSelector: '.chart-pie-legend',

    pieCanvasSelector: '.chart-pie-canvas',

    initPieChart: function() {
        var component = this

        $(this.pieCanvasSelector).each(function () {
            var chart = new Chart(this, {
                type: 'doughnut',
                data: {
                    labels: $(this).data('labels'),
                    datasets: [{
                        data: $(this).data('data'),
                        backgroundColor: component.generatePalette('cb-Set1', $(this).data('labels').length),
                        hoverBackgroundColor: component.generatePalette('cb-Pastel1', $(this).data('labels').length),
                    }],
                },
                options: {
                    legendCallback: component.pieLegendCallback,
                    legend: {
                        display: false,
                    },
                },
            })

            $(this).siblings(component.pieLegendSelector).html(chart.generateLegend())
        })
    },

    pieLegendCallback: function(chart) {
        var text = [];
        text.push('<ul class="list-unstyled ' + chart.id + '-legend">');

        var data = chart.data;
        var datasets = data.datasets;
        var labels = data.labels;

        if (datasets.length) {
            for (var i = 0; i < datasets[0].data.length; ++i) {
                text.push('<li><span class="fa fa-fw fa-circle" style="color:' + datasets[0].backgroundColor[i] + '"></span>');
                if (labels[i]) {
                    text.push(labels[i] + ' (' + datasets[0].data[i] + ')');
                }
                text.push('</li>');
            }
        }

        text.push('</ul>');
        return text.join("");
    },



    /**
     * Line
     */

    lineLegendSelector: '.chart-line-legend',

    lineCanvasSelector: '.chart-line-canvas',

    initLineChart: function() {
        var component = this

        $(this.lineCanvasSelector).each(function () {
            var chart = new Chart(this, {
                type: 'line',
                data: {
                    labels: $(this).data('labels'),
                    datasets: [{
                        data: $(this).data('data'),
                        backgroundColor: component.generatePalette('cb-Pastel1', 1, 0.5)[0],
                        borderColor: component.generatePalette('cb-Set1', 1, 0.5)[0],
                        borderWidth: 2,
                    }],
                },
                options: {
                    legend: {
                        display: false,
                    },
                },
            })
        })
    },



    /**
     * Bar
     */

    barLegendSelector: '.chart-bar-legend',

    barCanvasSelector: '.chart-bar-canvas',

    initBarChart: function() {
        var component = this

        $(this.barCanvasSelector).each(function () {
            var index = -1
            var canvas = $(this)
            var chart = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: canvas.data('labels'),
                    datasets: Object.keys(canvas.data('data')).map(function (key) {
                        return {
                            data: canvas.data('data')[key],
                            label: key,
                            backgroundColor: component.generatePalette('cb-Pastel1', Object.keys(canvas.data('data')).length, 0.5)[++index],
                            borderColor: component.generatePalette('cb-Set1', Object.keys(canvas.data('data')).length, 0.5)[index],
                            pointBorderColor: component.generatePalette('cb-Set1', Object.keys(canvas.data('data')).length, 0.5)[index],
                            pointBackgroundColor: component.generatePalette('cb-Set1', Object.keys(canvas.data('data')).length, 0.5)[index],
                            borderWidth: 2,
                        }
                    }),
                },
            })
        })
    },

})