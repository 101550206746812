
AppComponents.push({

    selector: '.timepicker',

    options: {
        defaultDate: false,
        format: 'LT',
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        },
        stepping: 60,
    },

    init: function() {
        this.options.locale = moment.locale()
        $(this.selector).parent().css('position', 'relative')
        $(this.selector).datetimepicker(this.options)
    },

})
