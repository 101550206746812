
AppComponents.push({

    selector: '.datepicker',

    options: {
        defaultDate: false,
        format: 'L',
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-crosshairs',
            clear: 'fa fa-trash',
            close: 'fa fa-times'
        },
    },

    init: function() {
        var component = this

        this.options.locale = moment.locale()

        $(this.selector).parent().css('position', 'relative')

        $(this.selector).each(function (index, element) {
            if ($(element).data('widget_positioning')) {
                component.options.widgetPositioning = $(element).data('widget_positioning')
            } else {
                component.options.widgetPositioning = {
                    horizontal: 'auto',
                    vertical: 'auto',
                }
            }

            $(element).datetimepicker(component.options)
        })
    },

})
